import { useSelector } from 'react-redux';
import convertPointsToCash from './convertPointsToCash';
import convertCashToPoints from './convertCashToPoints';
import { getLevels } from 'store/pointsConversion/pointsConversionSelectors';
import { getPointsLevels } from 'store/pointsBurnTiers/pointsBurnSelectors';

const usePointsConverters = (customTiers) => {
  const currentLevels = useSelector(getLevels);
  const defaultTiers = useSelector(getPointsLevels);

  const getConversionTiers = () => {
    if (customTiers) return customTiers;
    if (currentLevels.length) return currentLevels;
    return defaultTiers;
  };

  // const conversionTiers = customTiers || currentTiers || defaultTiers;

  return {
    convertPointsToCash: ({ points }) => convertPointsToCash({ points, conversionTiers: getConversionTiers() }),
    convertCashToPoints: ({ cash }) => convertCashToPoints({ cash, conversionTiers: getConversionTiers() }),
  };
};

export default usePointsConverters;
