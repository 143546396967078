import React from 'react';
import PropTypes from 'prop-types';
import { PayWithButtonGroup as RooPayWithButtonGroup } from '@qga/roo-ui/components';
import { useDispatch } from 'react-redux';
import { setIsPointsPay } from 'store/ui/uiActions';
import convertPayWithQueryParams from 'lib/search/convertPayWithQueryParams';
import usePointsConverters from 'hooks/points/usePointsConverters';
import { PAYMENT_METHODS } from 'lib/enums/payment';
import styled from '@emotion/styled';
import { mediaQuery } from 'lib/styledSystem';

const FullWidthForm = styled.form`
  width: 100%;

  ${mediaQuery.minWidth.sm} {
    width: 100%;
  }
  ${mediaQuery.minWidth.md} {
    width: ${(props) => (props.isHotelTab ? '194px' : '100%')};
  }
`;

const PayWithButtonGroup = ({
  payWith,
  minPrice,
  maxPrice,
  updateQuery,
  name,
  isDisabled = false,
  isLocked = false,
  isHotelTab = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { convertCashToPoints, convertPointsToCash } = usePointsConverters();

  const onChange = (payWith) => {
    if (payWith === PAYMENT_METHODS.CASH && isDisabled) dispatch(setIsPointsPay(false));
    updateQuery(convertPayWithQueryParams({ convertCashToPoints, convertPointsToCash, payWith, minPrice, maxPrice }));
  };

  return (
    <FullWidthForm isHotelTab={isHotelTab}>
      <RooPayWithButtonGroup {...rest} disabled={isDisabled} locked={isLocked} name={name} onChange={onChange} value={payWith} />
    </FullWidthForm>
  );
};

PayWithButtonGroup.propTypes = {
  name: PropTypes.string,
  payWith: PropTypes.string.isRequired,
  pointsPlusPayEnabled: PropTypes.bool,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  updateQuery: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  isHotelTab: PropTypes.bool,
};

PayWithButtonGroup.defaultProps = {
  name: 'payWith',
  isDisabled: false,
  isLocked: false,
  size: 'xs',
  minPrice: undefined,
  maxPrice: undefined,
  pointsPlusPayEnabled: false,
  isHotelTab: false,
};

export default PayWithButtonGroup;
