import { PAYMENT_METHODS } from 'lib/enums/payment';

const convertPayWithQueryParams = ({ convertCashToPoints, convertPointsToCash, payWith, minPrice, maxPrice }) => {
  if (payWith === PAYMENT_METHODS.CASH) {
    minPrice = minPrice ? convertPointsToCash({ points: minPrice }).round().toNumber() : undefined;
    maxPrice = maxPrice ? convertPointsToCash({ points: maxPrice }).round().toNumber() : undefined;
  } else if (payWith === PAYMENT_METHODS.POINTS) {
    minPrice = minPrice ? convertCashToPoints({ cash: minPrice }).round().toNumber() : undefined;
    maxPrice = maxPrice ? convertCashToPoints({ cash: maxPrice }).round().toNumber() : undefined;
  }

  return { payWith, minPrice, maxPrice };
};

export default convertPayWithQueryParams;
